import { IConnectedWallet } from "@src/store/user/wallet";
import { bech32 } from "bech32";
import { CIP30ProviderProxy, CIP30Wallet } from "kuber-client";
import { PersistPartial } from "redux-persist/es/persistReducer";

export async function getCip30Wallet(
  wallet: IConnectedWallet & PersistPartial,
): Promise<CIP30Wallet> {
  const providers = CIP30Wallet.listProviders();
  let provider: CIP30ProviderProxy = providers.filter(
    (provider) => provider.name === wallet.name,
  )[0];
  return await provider.enable({
    extensions: [
      {
        cip: 95,
      },
    ],
  });
}

export function toCIP105DRep(drepId: string) {
  const decodedDRepId = bech32.decode(drepId, 100);
  const drepIdHex = Buffer.from(bech32.fromWords(decodedDRepId.words))
    .toString("hex")
    .slice(-56);
  const cip105DRepId = bech32.encode(
    "drep",
    bech32.toWords(Buffer.from(drepIdHex, "hex")),
  );
  return cip105DRepId;
}

export async function getWalletBalance(
  wallet: IConnectedWallet & PersistPartial,
) {
  const kuberClientWallet = await getCip30Wallet(wallet);
  const walletBalance = await kuberClientWallet.calculateBalance();
  return walletBalance.lovelace.toString();
}
