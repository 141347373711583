import { CIP30Wallet } from "kuber-client";
import { blake2bHash, hexToBech32 } from "./stringUtils";
import { toast } from "react-toastify";
import { CIP30Instance } from "kuber-client/types";
import { ToastId } from "@src/constants/toastId";
import environments from "@src/configs/environments";

export async function getDRepIdFromCip30Wallet(wallet: CIP30Instance) {
  const networkId = await wallet.getNetworkId();
  if (networkId.toString() != environments.NETWORK_ID) {
    const isTestnet = networkId === 0;
    const toastMessage = `You're connected to the ${isTestnet ? "testnet" : "mainnet"}. Please switch your wallet to the ${isTestnet ? "mainnet" : "testnet"} or choose a different wallet.`;

    toast.error(toastMessage, {
      toastId: ToastId.ERROR_TOAST,
    });
    return;
  }

  const dRepPubKey = (await wallet.cip95?.getPubDRepKey()) || "";
  if (dRepPubKey == "") {
    toast.error("Your wallet is not CIP-95 compatible", {
      toastId: ToastId.ERROR_TOAST,
    });
    return;
  }
  // prefix 22 is for making drepId cip-129 compatible for metadata validation
  const hexDRepId = "22" + blake2bHash(dRepPubKey);
  return hexToBech32(hexDRepId);
}

export function lovelaceToAda(lovelace: number) {
  const Ada = lovelace / 1000000;
  return Math.floor(Ada);
}

export function getTokenFeeInAda(tokenName: string) {
  const tokenNameLength = tokenName.length;

  // Calculate the fee based on token name length
  return tokenNameLength > 4 ? "1" : (6 - tokenNameLength).toString();
}

export function formatAda(ada: number): string {
  if (ada >= 1_000_000_000) {
    return `${(ada / 1_000_000_000).toFixed(2)}B`;
  } else if (ada >= 1_000_000) {
    return `${(ada / 1_000_000).toFixed(2)}M`;
  } else if (ada >= 1_000) {
    return `${(ada / 1_000).toFixed(2)}K`;
  } else {
    return ada.toString();
  }
}
